import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getArticleList = createAsyncThunk(
  "blog/list",
  async (_, thunkAPI) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/blog/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getArticleDetail = createAsyncThunk(
  "blog/detail",
  async (slug, thunkAPI) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/blog/detail`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          slug: slug,
        },
      });

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  articleLoading: false,
  articleList: null,
  articleDetailLoading: false,
  articleDetail: null
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getArticleList.pending, (state) => {
        state.articleLoading = true;
      })
      .addCase(getArticleList.fulfilled, (state, action) => {
        state.articleLoading = false;
        state.articleList = action.payload;
      })
      .addCase(getArticleList.rejected, (state) => {
        state.articleLoading = false;
      })
      .addCase(getArticleDetail.pending, (state) => {
        state.articleDetailLoading = true;
      })
      .addCase(getArticleDetail.fulfilled, (state, action) => {
        state.articleDetailLoading = false;
        state.articleDetail= action.payload;
      })
      .addCase(getArticleDetail.rejected, (state) => {
        state.articleDetailLoading = false;
      });
  },
});

export default blogSlice.reducer;
