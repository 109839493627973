import { useSelector } from "react-redux";
import { slide as Menu } from "react-burger-menu";
import { Image, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { CardHeader } from "@mui/material";
import ReportPageAccordian from "../../components/accordians/ReportPageAccordians";
import MenuAccordian from "../../components/accordians/MenuAccordian";
import AtomLogo from "assets/images/logo/atomLogo.png";
import Logo2 from "assets/images/logo/ghfLogo2.png";
import "./sidebar.scss";
import {
  ADMIN_MENU,
  CLIENT_ENGAGEMENT_MENU,
  ADMIN_REGISTER_REQUEST,
  ADMIN_REPORT_LIST,
  ADMIN_POST_FILTERTYPE,
  ADMIN_POST_FILTERVALUES,
  ADMIN_UPDATE_REPORT_GROUP,
  ADMIN_POST_REPORT_GROUP,
  ADMIN_POST_USERACCOUNT,
  ADMIN_USER_MONITOR,
  TICKET_OVERVIEW,
  PROPHET_LOGS,
  ATOM_TICKETS,
  PROJECT_TICKETS,
  ZOHO_TICKETS,
} from "../../utils/types";

import { BulletList } from "react-content-loader";


const Sidebar = () => {

  const { isToggled } = useSelector((state) => state.isSidebarToggled);
  const { reportListLoading, reportPagesLoading, reportPages } = useSelector(
    (state) => state.pbiReducer
  );

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { user, subscriptions, ticketsLoading } = useSelector(
    (state) => state.userInfo
  );

  const companyname = user === null ? "Atom DTS" : user.customer_name;
  const companylogoUrl =
    user === null ? "logos/Atom_DTS.png" : user.customerLogoName;

  const companyLogoLight =
    user === null || !user || user.customerLogoLight === undefined
      ? undefined
      : user.customerLogoLight;
  const companyLogoDark =
    user === null || !user || user.customerLogoDark === undefined
      ? undefined
      : user.customerLogoDark;

  const userIstaff = user === null ? false : user.is_staff;

  const pbi_sub = subscriptions ? subscriptions.hasOwnProperty("pbi") : false;
  const cep_sub = subscriptions ? subscriptions.hasOwnProperty("cep") : false;
  const ztp_sub = subscriptions ? subscriptions.hasOwnProperty("ztp") : false;

  const imageLocation = process.env.REACT_APP_STORAGE_URL + "/" + companylogoUrl;
  const engagmentItems = [
    {
      name: TICKET_OVERVIEW,
      description: TICKET_OVERVIEW,
    },
    {
      name: PROPHET_LOGS,
      description: PROPHET_LOGS,
    },
    {
      name: ATOM_TICKETS,
      description: ATOM_TICKETS,
    },
    {
      name: PROJECT_TICKETS,
      description: PROJECT_TICKETS,
    },
  ];


  const zohoTicketItems = [
    {
      name: TICKET_OVERVIEW,
      description: TICKET_OVERVIEW,
    },
    {
      name: ZOHO_TICKETS,
      description: ZOHO_TICKETS,
    },
  ];

  const adminItems = [
    { name: ADMIN_REPORT_LIST, description: ADMIN_REPORT_LIST },
    { name: ADMIN_REGISTER_REQUEST, description: ADMIN_REGISTER_REQUEST },
    { name: ADMIN_POST_FILTERTYPE, description: ADMIN_POST_FILTERTYPE },
    { name: ADMIN_POST_FILTERVALUES, description: ADMIN_POST_FILTERVALUES },
    { name: ADMIN_UPDATE_REPORT_GROUP, description: ADMIN_UPDATE_REPORT_GROUP },
    { name: ADMIN_POST_REPORT_GROUP, description: ADMIN_POST_REPORT_GROUP },
    { name: ADMIN_POST_USERACCOUNT, description: ADMIN_POST_USERACCOUNT },
    { name: ADMIN_USER_MONITOR, description: ADMIN_USER_MONITOR },
  ];


  return isToggled === true && isMobile === false ? (
    <div className="sidebar">
      <div className="topbar">
        <CardHeader
          avatar={
            <Image
              alt="Good Hope Fruit logo"
              src={Logo2}
              width="150"
              className="topbar-list"
            />
          }
          // title={companyname}
          // classes={{
          //   title: "company",
          //   avatar: "avatar",
          // }}
        />
      </div>
      {/* <Row className="justify-content-md-center">
        <hr />
      </Row> */}

      <div className="center">
        {pbi_sub ? (
          <>
            {reportPagesLoading && !reportPages? (
              <>
                <BulletList />
                <BulletList />
              </>
            ) : (
              <ul>
                <ReportPageAccordian />
              </ul>
            )}
          </>
        ) : (
          <></>
        )}

{cep_sub ? (
          <>
            {/* {ticketsLoading ? (
              <BulletList />
            ) : ( */}
              <ul>
                <MenuAccordian
                  sectionName="Client Engagement"
                  pagedict={engagmentItems}
                  itemTypes={CLIENT_ENGAGEMENT_MENU}
                />
              </ul>
            {/* )} */}
          </>
        ) : (
          <></>
        )}
        {userIstaff ? (
          <ul>
            <MenuAccordian
              sectionName="Admin"
              pagedict={adminItems}
              itemTypes={ADMIN_MENU}
            />
          </ul>
        ) : (
          <></>
        )}
      </div>

      <div className=" bottomHide">
        <Row className="justify-content-md-center">
          <hr />
          <Col lg="auto">
            <p className="">Powered by:</p>
            <img src={AtomLogo} width="130px" className="mb-3" alt="logo" />
          </Col>
        </Row>
      </div>
    </div>
  ) : isMobile === true && isToggled === true ? (
    <Menu width={"240px"} isOpen={isToggled}>
      <div className="sidebar">
        <div className="topbar">
          <li className="topbar-list mt-3">
            <Image
              roundedCircle
              alt="Good Hope Fruit logo"
              src={Logo2}
              width="100"
              className="image-logo"
            />
          </li>
        </div>
        <Row className="justify-content-center">
          <hr />
        </Row>
        <div className="center">
          {pbi_sub ? (
            <>
            {reportPagesLoading && !reportPages? (
                <>
                  <BulletList />
                  <BulletList />
                </>
              ) : (
                <ul>
                  <ReportPageAccordian />
                </ul>
              )}
            </>
          ) : (
            <></>
          )}

          {cep_sub ? (
            <>
              {ticketsLoading ? (
                <BulletList />
              ) : (
                <ul>
                  <MenuAccordian
                    sectionName="Client Engagement"
                    pagedict={engagmentItems}
                    itemTypes={CLIENT_ENGAGEMENT_MENU}
                  />
                </ul>
              )}
            </>
          ) : (
            <></>
          )}


        </div>

        <div className="bottomHide">
          <Row className="justify-content-center">
            <Col lg="auto" xs="auto">
              <p className="fs-12">Powered by:</p>
              <img src={AtomLogo} width="80px" className="mb-3" alt="logo" />
            </Col>
          </Row>
        </div>
      </div>
    </Menu>
  ) : (
    <></>
  );
};

export default Sidebar;
