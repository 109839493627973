import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import SelectComponent from "../../components/select/Select";
import { useSelector } from "react-redux";
import { BulletList } from "react-content-loader";
import TicketAccordion from "../../components/accordians/TicketAccordian";
import "./tickets.scss";
import {
  DEPARTMENT_NAME,
  PROJECT_NAME,
  TICKET_STATUS,
  TICKET_PRIORITY,
  TICKET_NUMBER,
  TAXONOMY,
  IS_OVERDUE,
} from "../../utils/types";

function filterLogsByDepartment(category) {
  switch (category) {
    case "Prophet Logs":
      return ["Prophet"];
    case "Atom Tickets":
      return ["ATOM DTS Services", "ATOM DTS Quick Tickets"];
    case "Projects":
      return ["ATOM DTS Data Solutions"];
    default:
      return [];
  }
}

const colArray = [];
for (let i = 0; i < 2; i++) {
  colArray.push(
    <Col xs={12} key={i}>
      <BulletList />
    </Col>
  );
}

const TicketComponent = () => {
  const { section } = useSelector((state) => state.selectedMenuSection);
  const { tickets, ticketsLoading } = useSelector((state) => state.userInfo);
  const [shownTickets, setShownTickets] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    department: ["Prophet"],
    project: [],
    number: [],
    priority: [],
    status: [],
    taxonomy: [],
    overdue: [],
  });

  const getFilteredTickets = () => {
    if (tickets) {
      let sortedTickets = tickets
      let filteredTickets = filterSelectObject(sortedTickets, filterOptions)
      setShownTickets(filteredTickets);
    }
  };

  useEffect(() => {
    if (tickets) {
      const sortedTickets = tickets
      setShownTickets(sortedTickets);
      getFilteredTickets();
    }
  }, [tickets]);

  useEffect(() => {
    if (section !== "") {
      setFilterOptions({
        project: [],
        number: [],
        priority: [],
        status: [],
        taxonomy: [],
        overdue: [],
        department: filterLogsByDepartment(section),
      });
    }
  }, [section]);

  useEffect(() => {
    getFilteredTickets();
  }, [filterOptions]);

  function getUniqueValues(name, keyName) {
    const tempFilterOptions = {
      ...filterOptions,
      [name]: [],
    };

    if (tickets) {
      let dataFiltered = filterSelectObject(tickets, tempFilterOptions);
      const uniqueValues = [
        ...new Set(dataFiltered.map((item) => item[keyName])),
      ];
      const uniqueValuesList = uniqueValues.map((value) => ({
        label: value,
        value: value,
      }));

      const sortedUniqueValuesList = uniqueValuesList.sort();

      return sortedUniqueValuesList;
    } else {
      return [];
    }
  }

  const filterSelectObject = (ticket_object, filter_options) => {
    let filteredTickets = ticket_object.filter((data) => {
      let match = true;
      if (filter_options.project.length > 0) {
        match = match && filter_options.project.includes(data[PROJECT_NAME]);
      }
      if (filter_options.status.length > 0) {
        match = match && filter_options.status.includes(data[TICKET_STATUS]);
      }
      if (filter_options.priority.length > 0) {
        match =
          match && filter_options.priority.includes(data[TICKET_PRIORITY]);
      }
      if (filter_options.number.length > 0) {
        match = match && filter_options.number.includes(data[TICKET_NUMBER]);
      }
      if (filter_options.taxonomy.length > 0) {
        match = match && filter_options.taxonomy.includes(data[TAXONOMY]);
      }
      if (filter_options.overdue.length > 0) {
        match = match && filter_options.overdue.includes(data[IS_OVERDUE]);
      }
      if (filter_options.department.length > 0) {
        match =
          match && filter_options.department.includes(data[DEPARTMENT_NAME]);
      }
      return match;
    });

    return filteredTickets;
  };

  const handleChange = (event, options, name) => {
    if (event.target) {
      const { value } = event.target;
      if (value.includes("All")) {
        if (value.length > options.length) {
          setFilterOptions((prevOptions) => ({
            ...prevOptions,
            [name]: [],
          }));
        } else {
          const outputValues = options.map((obj) => obj.value);
          setFilterOptions((prevOptions) => ({
            ...prevOptions,
            [name]: outputValues,
          }));
        }
      } else {
        setFilterOptions((prevOptions) => ({
          ...prevOptions,
          [name]: value,
        }));
      }
    }
  };

  const selectProps = [
    {
      title: "Project",
      name: "project",
      options: getUniqueValues("project", PROJECT_NAME),
      isMultiple: true,
      value: filterOptions.project,
    },
    {
      title: "Log / Ticket Number",
      name: "number",
      options: getUniqueValues("number", TICKET_NUMBER),
      isMultiple: true,
      value: filterOptions.number,
    },
    {
      title: "Log / Ticket Priority",
      name: "priority",
      options: getUniqueValues("priority", TICKET_PRIORITY),
      isMultiple: true,
      value: filterOptions.priority,
    },
    {
      title: "Overdue",
      name: "overdue",
      options: getUniqueValues("overdue", IS_OVERDUE),
      isMultiple: true,
      value: filterOptions.overdue,
    },
    {
      title: "Log / Ticket Status",
      name: "status",
      options: getUniqueValues("status", TICKET_STATUS),
      isMultiple: true,
      value: filterOptions.status,
    },
    {
      title: "Taxonomy",
      name: "taxonomy",
      options: getUniqueValues("taxonomy", TAXONOMY),
      isMultiple: true,
      value: filterOptions.taxonomy,
    },
  ];
  return (
    <>
      {!ticketsLoading && shownTickets ? (
        <>
          <div className="selector-box">
            <Row className="align-items-top">
              {selectProps.map((props) => (
                <Col xs="auto">
                  <SelectComponent {...props} onChange={handleChange} />
                </Col>
              ))}
            </Row>
          </div>
          <div className="ticket-box">
            {shownTickets.map((log) => (
              <TicketAccordion {...log} />
            ))}
          </div>
        </>
      ) : (
        <Row className="align-items-top">{colArray}</Row>
      )}
    </>
  );
};

export default TicketComponent;
