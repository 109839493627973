import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Logo from "../../../assets/images/logo/ghfLogo2.png";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { reset_password_confirm } from "slice/user";
import Popup from "hubl/components/popup/Popup";
import Login from "../../../assets/Login.png";
import "./password.scss";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{6,24}$/;

const PWD_UPPER = new RegExp("((?=.*[A-Z]))");
const PWD_LOWER = new RegExp("((?=.*[a-z]))");
const PWD_NUMBER = new RegExp("((?=.*[0-9]))");
const PWD_SPECIAL = new RegExp("((?=.*[@$!%*#?&]))");
const PWD_LEN = new RegExp("([A-Za-zd@$!#%*?&]{6,})");

const formInput = {
  borderRadius: "10px",
  borderColor: "#e3e3e3",
};

const buttonObj = {
  height: "50px",
  width: "250px",
  marginTop: "30px",
  marginLeft: "3px",
  paddingLeft: "15px",
  paddingRight: "15px",
  color: "#ffffff",
  backgroundColor: "#f59e09",
  borderColor: "#f59e09",
  borderRadius: "20px",
};

const ResetPasswordConfirm = () => {
  // const [requestSent, setRequestSent] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });
  const { uid, token } = useParams();
  const { new_password, re_new_password } = formData;
  const [errMsg, setErrMsg] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [open, setOpen] = useState(false);
  const [popHeader, setPopHeader] = useState("");
  const [popContent, setPopContent] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!PWD_LEN.test(new_password)) {
      setErrMsg("Password must be at least 6 characters long");
    } else if (!PWD_LOWER.test(new_password)) {
      setErrMsg("Password must contain a lowercase letter");
    } else if (!PWD_UPPER.test(new_password)) {
      setErrMsg("Password must contain an uppercase letter");
    } else if (!PWD_SPECIAL.test(new_password)) {
      setErrMsg("Password must contain a special character");
    } else if (!PWD_NUMBER.test(new_password)) {
      setErrMsg("Password must contain a number");
    }

    setValidPwd(PWD_REGEX.test(new_password));
    setValidMatch(new_password === re_new_password);
  }, [new_password, re_new_password]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validPwd) {
      setOpen(true);
      setPopContent(errMsg);
    } else if (!validMatch) {
      setOpen(true);
      setPopContent("Passwords are not matching");
    }

    if (validPwd && validMatch) {
      dispatch(
        reset_password_confirm({ uid, token, new_password, re_new_password })
      ).then((value) => {
        if (value.meta.requestStatus == "fulfilled") {
          navigate("/login");
        } else {
          setOpen(true);
          switch (value.error.message) {
            case "Rejected":
              setPopHeader("Register Failed");
              if (value.payload.email) {
                setPopContent(value.payload.email[0]);
              } else if (value.payload.password) {
                setPopContent(value.payload.password[0]);
              } else {
                setPopContent(value.error.message);
              }

            default:
          }
        }
      });
    } else {
      setOpen(true);
      setPopContent(errMsg);
    }
  };
  return (
    <>
      <Helmet>
        <title>Producer Portal | Confirm new password</title>
        <meta name="description" content={"Analytics Portal"} />
      </Helmet>
      <div className="register">
        <Popup
          open={open}
          header={popHeader}
          content={popContent}
          onClose={handleClose}
          image={Login}
        />
        <Container
          className="registerContainer"
          style={{
            marginTop: "auto",
            width: "500px",
            padding: "50px",
          }}
        >
          <Row className="rowWidth">
            <Col>
              <Form>
                <div className="heading-register">
                  <img
                    className="logo-login"
                    style={{ display: "block", margin: "auto", width: "80%" }}
                    src={Logo}
                    alt="confirm password logo"
                  />
                </div>
                <h3 className="heading-title">Set new password</h3>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password*"
                    style={formInput}
                    name="new_password"
                    value={new_password}
                    onChange={(e) => onChange(e)}
                    minLength="6"
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                  />
                </Form.Group>
                <p
                  id="pwdnote"
                  className={!validPwd ? "instructions" : "offscreen"}
                >
                  6 to 24 characters.
                  <br />
                  Must include uppercase and lowercase letters, a number and a
                  special character.
                  <br />
                  Allowed special characters:{" "}
                  <span aria-label="exclamation mark">!</span>{" "}
                  <span aria-label="at symbol">@</span>{" "}
                  <span aria-label="hashtag">#</span>{" "}
                  <span aria-label="dollar sign">$</span>{" "}
                  <span aria-label="percent">%</span>
                </p>

                <Form.Group className="mb-3" controlId="confirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password*"
                    style={formInput}
                    name="re_new_password"
                    value={re_new_password}
                    onChange={(e) => onChange(e)}
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmnote"
                  />
                  <p
                    id="confirmnote"
                    className={!validMatch ? "instructions" : "offscreen"}
                  >
                    Must match the first password input field.
                  </p>
                </Form.Group>

                <div className="d-grid gap-2 button-container">
                  <Button
                    // variant="dark"
                    type="submit"
                    size="lg"
                    style={buttonObj}
                    onClick={(e) => onSubmit(e)}
                    href={"/"}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResetPasswordConfirm;
