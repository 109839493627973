import React from "react";
import { useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import ReportListStatusComponent from "./ReportList";
import RegisterRequestComponent from "./RegisterRequest";
import PostFilterTypeComponent from "./FilterTypePost";
import PostFilterValuesComponent from "./FilterValuePost";
import UserAccountUpdateComponent from "./UserAccountForm";
import UpdateReportGroupComponent from "./UpdateReportGroup";
import PostReportGroupComponent from "./CreateReportGroup";
import UserMonitorComponent from "../recharts/UserMonitor";
// import UserMonitorTableComponent from "../recharts/UserMonitorTables";
import Grid from "@mui/material/Grid";
import Header from "../../components/charts/Header";
import { themeSettings } from "../../theme";
import Box from "@mui/material/Box";

import {
  ADMIN_REGISTER_REQUEST,
  ADMIN_REPORT_LIST,
  ADMIN_POST_FILTERTYPE,
  ADMIN_POST_FILTERVALUES,
  ADMIN_POST_REPORT_GROUP,
  ADMIN_UPDATE_REPORT_GROUP,
  ADMIN_POST_USERACCOUNT,
  ADMIN_USER_MONITOR,
} from "../../utils/types";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#ed5210",
//       contrastText: "#3b3b42",
//     },
//   },
//   components: {
//     MuiInputBase: {
//       styleOverrides: {
//         root: {
//           minWidth: "200px",
//           height: "auto",
//           "&::before": {
//             display: "none",
//           },
//           backgroundColor: "#FFFFFF",
//           border: "1px solid #D6D6D6",
//           borderRadius: "8px",
//           opacity: 1,
//           color: "#3B3B42",
//           paddingLeft: "15px",
//         },
//       },
//     },

//     MuiButton: {
//       styleOverrides: {
//         root: {
//           height: "50px",
//         },
//         containedPrimary: {
//           backgroundColor: "#ed5210",
//           color: "#3b3b42",
//         },
//       },
//     },
//   },
// });

function AdminComponent() {
  let componentToRender;
  const darkMode = useSelector((state) => state.darkMode);
  const theme = createTheme(themeSettings(darkMode ? "dark" : "light"));
  const { section } = useSelector((state) => state.selectedMenuSection);

  switch (section) {
    case ADMIN_REPORT_LIST:
      componentToRender = <ReportListStatusComponent />;
      break;
    case ADMIN_REGISTER_REQUEST:
      componentToRender = <RegisterRequestComponent />;
      break;
    case ADMIN_POST_FILTERTYPE:
      componentToRender = <PostFilterTypeComponent />;
      break;
    case ADMIN_POST_FILTERVALUES:
      componentToRender = <PostFilterValuesComponent />;
      break;
    case ADMIN_POST_REPORT_GROUP:
      componentToRender = <PostReportGroupComponent />;
      break;

    case ADMIN_UPDATE_REPORT_GROUP:
      componentToRender = <UpdateReportGroupComponent />;
      break;

    case ADMIN_POST_USERACCOUNT:
      componentToRender = <UserAccountUpdateComponent />;
      break;
    case ADMIN_USER_MONITOR:
      componentToRender = <UserMonitorComponent />;

      break;
    default:
      componentToRender = <div>Page not found</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="admin-box">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={4}>
                <Header title={`Admin: ${section}` }/>
              </Grid>
              <Grid item xs={4} sm={4}></Grid>

              <Grid item xs={4} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    height: "100%",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            {componentToRender}
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default AdminComponent;
