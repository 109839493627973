import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./hubl/pages/dashboard/Dashboard";
import LoginPage from "./hubl/pages/login/Login";
import Register from "./hubl/pages/register/Register";
import NotFound from "./hubl/components/NotFound";
import PrivateRoute from "./hubl/utils/PrivateRoute";
import ResetPassword from "./hubl/pages/password/ResetPassword";
import ResetPasswordConfirm from "./hubl/pages/password/ConfirmResetPassword";

import "react-toastify/dist/ReactToastify.css";
import "./style/dark.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";

const App = () => {
  const darkMode = useSelector((state) => state.darkMode);
  return (
    <HelmetProvider>
      <Helmet>
        <title>
        Good Hope Fruit
        </title>
        <meta
          name="description"
          content="Good Hope Fruit"
        />
        ;
      </Helmet>
      <div className={darkMode ? "app dark" : "app"}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <Routes>
            <Route path="/">
              <Route index element={<LoginPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route
                path="password/reset/confirm/:uid/:token"
                element={<ResetPasswordConfirm />}
              />
              {/* <Route path='activate/:uid/:token' element={Activate} /> */}
              <Route path="*" element={<NotFound />} />
              <Route element={<PrivateRoute />}>
                <Route path="portal" element={<Dashboard />} />
              </Route>
              {/* <Route path="landing" element={<TicketAccordian />} /> */}
              <Route path="register" element={<Register />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
};

function wait(time) {
  return new Promise((reslove) => {
    setTimeout(reslove, time);
  });
}

export default App;
