import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import "./textfield.scss";

export default function TextFieldsComponent({
  title,
  name,
  value,
  placeholder,
  onChange,
  InputProps,
}) {
  return (
    <Box
      // component="form"
      // sx={{
      //   "& > :not(style)": { m: 1, width: "25ch" },
      // }}
    >
      <div className="text-label">{title}</div>
      <TextField
        hiddenLabel
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        margin="normal"
        InputProps={InputProps}
      />
    </Box>
  );
}
