import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Popup from "../../components/popup/Popup";
import {
  resetRegistered,
  login,
  logUserLogin,
  getUser,
  getUserSubscriptions,
  getUserTickets,
} from "slice/user";
import Login from "../../../assets/Login.png";
import "./login.scss";
import Logo from "../../../assets/images/logo/ghfLogo2.png";
import { getUserReportList, getReportPages, getReportList, selectReport, setLoadingPages } from "slice/pbi";
import { selectDashboardSection } from "slice/dashboardSection";
import { CLIENT_ENGAGEMENT_MENU, PBI_MENU, ZOHO_TICKET_MENU } from "hubl/utils/types.js";
import { setRememberMe } from "slice/user";




const buttonObj = {
  height: "50px",
  marginTop: "2px",
  marginLeft: "3px",
  paddingLeft: "15px",
  paddingRight: "15px",
  color: "#ffffff",
  backgroundColor: "#f59e09",
  borderColor: "#f59e09",
  borderRadius: "20px",
};

const formInput = {
  borderRadius: "10px",
  borderColor: "#e3e3e3",
};

const banner = {
  color: "#ffffff",
  backgroundColor: "#3b3b42",
  padding: "60px 40px 20px 40px",
  borderRadius: "0px 15px 15px 0px",
};

const bannerSignUp = {
  color: "#3b3b42",
  backgroundColor: "#ffffff",
  padding: "20px 45px 40px 45px",
  borderRadius: "15px 15px 15px 15px",
};

const header = {
  lineHeight: "60px",
};

const LoginPage = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { registered } = useSelector((state) => state.userInfo);
  const { section } = useSelector((state) => state.dashboardSection);
  const { report_id } = useSelector((state) => state.pbiReducer);
  const { loading, isAuthenticated } = useSelector((state) => state.userInfo);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember_me: false,
  });

  useEffect(() => {
    if (registered) dispatch(resetRegistered());
  }, [registered]);

  useEffect(() => {
    if (isAuthenticated && !loading) {
      navigate("/portal");
    }
  }, [loading, isAuthenticated]);

  const { email, password, remember_me } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [open, setOpen] = useState(false);
  const [popHeader, setPopHeader] = useState("");
  const [popContent, setPopContent] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const remember_me_bool = remember_me == "on" ? true : false;
    dispatch(setRememberMe(remember_me_bool))
    dispatch(setLoadingPages(true))
    dispatch(
      login({ email: email, password: password, remember_me: remember_me_bool })
    ).then((value) => {
      if (!value.payload.access) {
        
        setOpen(true);
        if (!section) {
          switch (value.error.message) {
            case "Rejected":
              setPopHeader("Login Failed");
              if (value.payload.detail) {
                setPopContent(value.payload.detail);
              } else {
                setPopContent(value.payload.error);
              }

            default:
          }
        }
      } else {
        dispatch(logUserLogin());
        dispatch(getUser());
        dispatch(getUserSubscriptions()).then((value) => {
          if (value.payload.hasOwnProperty("pbi")) {
            dispatch(getUserReportList()).then((value) => {
              const dataSorted = [...value.payload].sort(
                (a, b) => a.relative_sort - b.relative_sort
              );

              if (!report_id) {
                const selected_report = dataSorted[0].report_id;
                dispatch(selectReport(selected_report));
              }
              dispatch(getReportList())
              dispatch(getReportPages())
            });
          }

          if (value.payload.hasOwnProperty("cep")) {
            dispatch(getUserTickets({organization_id: 1}));
          }

          if (value.payload.hasOwnProperty("ztp")) {
            dispatch(getUserTickets({organization_id: 2}));
          }

          const sortedKeys = Object.keys(value.payload).sort(
            (a, b) =>
              value.payload[a].relative_sort - value.payload[b].relative_sort
          );

          const firstKeyWithSortOne = sortedKeys.find(
            (key) => value.payload[key].relative_sort === 1
          );
          

          if (!section && firstKeyWithSortOne) {
            switch (firstKeyWithSortOne) {
              case "pbi":
                dispatch(selectDashboardSection(PBI_MENU));
                break;
              case "cep":
                dispatch(selectDashboardSection(CLIENT_ENGAGEMENT_MENU));
                break;
              case "ztp":
                  dispatch(selectDashboardSection(ZOHO_TICKET_MENU));
                  break;
  
              default:
                break;
            }
          } else {
            console.log("No subscription found");
          }
        });
        
      }
    });
  };

  return (
    <div className="login">
      {/* <NavbarMain /> */}
      <Popup
        open={open}
        header={popHeader}
        content={popContent}
        onClose={handleClose}
        image={Login}
      />
      <Container
        className="loginContainer"
        style={{
          width: "500px",
        }}
      >
        <Row className="rowWidth">
          <Col style={bannerSignUp}>
            <Form>
              <img
                className="logo-login"
                style={{ display: "block", margin: "auto", width: "80%" }}
                src={Logo}
                alt="login logo"
              />
              <h3>Sign In</h3>
              <p className="create-account text-right">
                {/* <span>
                    New User?
                    <a style={{ marginLeft: ".5rem" }} href="#">
                      Create Account
                    </a>
                  </span> */}
              </p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  style={formInput}
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  tabIndex="1"
                  required
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  style={formInput}
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                  minLength="6"
                  tabIndex="2"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Remember me?"
                  name="remember_me"
                  tabIndex="4"
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <div className="d-grid gap-2">
                <Button
                  // variant="dark"
                  type="submit"
                  size="lg"
                  style={buttonObj}
                  onClick={(e) => onSubmit(e)}
                  href={"/"}
                  tabIndex="3"
                >
                  Submit
                </Button>
              </div>
              <p className="forgot-password text-right">
                Forgot <Link to="/reset-password">password?</Link>
              </p>
            </Form>
          </Col>

          {/* <Col
            style={banner}
            className="row align-items-center d-none d-lg-block"
          >
            <div className="banner-login mt-5">
              <h1 style={header}>
                <span style={{ fontWeight: "normal" }}>Fresh</span>
                <span style={{ fontWeight: "bold" }}>HUBL</span>
              </h1>
              <p className="text-white-50 fs-16 mb-5 p-content">
                Powered by Atom's analytics architecture, this web-based
                analytics portal gives you access to your business data from
                anywhere.
              </p>
              <VideoModal />
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;

{
  /* <div className="login">
      <NavbarMain />
      <Popup
        open={open}
        header={popHeader}
        content={popContent}
        onClose={handleClose}
      />
      <Container
        className="loginContainer"
        style={{
          width: "800px",
        }}
      >
        <Row className="rowWidth">
          <Col style={bannerSignUp}>
            <Form>
              <img className="logo-login" src={Logo} alt="login logo" />
              <h3>Sign In</h3>
              <p className="create-account text-right">
              </p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  style={formInput}
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  tabIndex="1"
                  required
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  style={formInput}
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                  minLength="6"
                  tabIndex="2"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Remember me?"
                  name="remember_me"
                  tabIndex="4"
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <div className="d-grid gap-2">
                <Button
                  variant="dark"
                  type="submit"
                  size="lg"
                  style={buttonObj}
                  onClick={(e) => onSubmit(e)}
                  href={"/"}
                  tabIndex="3"
                >
                  Submit
                </Button>
              </div>
              <p className="forgot-password text-right">
                Forgot <Link to="/reset-password">password?</Link>
              </p>
            </Form>
          </Col>

          <Col
            style={banner}
            className="row align-items-center d-none d-lg-block"
          >
            <div className="banner-login mt-5">
              <h1 style={header}>
                <span style={{ fontWeight: "normal" }}>Fresh</span>
                <span style={{ fontWeight: "bold" }}>HUBL</span>
              </h1>
              <p className="text-white-50 fs-16 mb-5 p-content">
                Powered by Atom's analytics architecture, this web-based
                analytics portal gives you access to your business data from
                anywhere.
              </p>
              <VideoModal />
            </div>
          </Col>
        </Row>
      </Container>
    </div> */
}
