import { combineReducers } from 'redux'
import userReducer from '../slice/user'
import pbiReducer from '../slice/pbi'
import blogReducer from '../slice/blog'
import setReportConfigSlice from '../slice/reportConfig'
import sessionTimeoutSlice from '../slice/session'
import selectDarkModeSlice from '../slice/theme'
import toggleSidebarSlice from '../slice/toggleSidebar'
import selectDashboardSection from '../slice/dashboardSection'
import selectMenuSecionSlice from '../slice/menuSection'
import adminReducer from '../slice/admin'
import notificationSlice from '../slice/notificationSlice'
import commentsSlice from '../slice/commentSlice'
import ticketSlice from 'slice/ticketSlice'
import userMonitorReducer from '../slice/userMonitorSlice'

export default combineReducers({
    darkMode: selectDarkModeSlice,
    pbiReducer: pbiReducer,
    userInfo: userReducer,
    adminInfo: adminReducer,
    blogInfo: blogReducer,
    reportConfig: setReportConfigSlice,
    sessionTimeout: sessionTimeoutSlice,
    selectedMenuSection: selectMenuSecionSlice,
    isSidebarToggled: toggleSidebarSlice,
    userMonitor: userMonitorReducer,
    dashboardSection: selectDashboardSection,
    notifications: notificationSlice,
    comments: commentsSlice,
    tickets: ticketSlice
})