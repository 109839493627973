import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Code } from "react-content-loader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TicketDetailComponent from "./TicketDetail";
import { getUserTicketSingle } from "slice/user";
import AtomLogo from "../../../assets/images/logo/Atom DTS.png";
import ProphetLogo from "../../../assets/images/logo/Prophet.png";
import { TICKET_NUMBER } from "../../utils/types";
import LinkIcon from "@mui/icons-material/Link";
import FlagIcon from "@mui/icons-material/Flag";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import Grid from "@mui/material/Grid";
import CommentSectionComponent from "../comments/CommentSection";
import { themeSettings } from "../../theme";
import { tokens } from "../../theme";
import { createTheme } from "@mui/material/styles";
import { createFlaggedTicket, deleteFlaggedTicket, addFlag, removeFlag } from "slice/ticketSlice";
import { useEffect } from "react";

function getPriorityLabel(ticketPriority) {
  let priorityLabel = "";
  switch (ticketPriority) {
    case "Low (min 2 weeks)":
      priorityLabel = "Low";
      break;
    case "Medium (max 5 days)":
      priorityLabel = "Medium";
      break;
    case "High (max 2 days)":
      priorityLabel = "High";
      break;
    default:
      priorityLabel = "Medium";
      break;
  }
  return priorityLabel.toLowerCase();
}
function getTicketLogo(companyName) {
  let companyLogo = "";
  switch (companyName) {
    case "ATOM DTS Services":
      companyLogo = AtomLogo;
      break;
    case "ATOM DTS Quick Tickets":
      companyLogo = AtomLogo;
      break;
    case "Prophet":
      companyLogo = ProphetLogo;
      break;
    default:
      companyLogo = AtomLogo;
      break;
  }
  return companyLogo;
}

const TicketAccordion = ({
  handleChange,
  expanded,
  Category,
  Account_Name,
  Log_or_Ticket_Status,
  Department_Name,
  Log_or_Ticket_Number,
  Date_Created,
  Subject,
  Log_or_Ticket_Priority,
  Classification,
  Due_Date,
  Is_Overdue,
  Taxonomy,
  RCA,
  Project_Name,
  Log_or_Ticket_Owner,
  Partner_Reference,
  Link,
  Partner_Link,
  Quote_Description,
  Release_Version,
  Estimated_Release_Date,
}) => {
  const dispatch = useDispatch();

  // const [isOpen, setIsOpen] = useState(false);
  const [isPartnerExpanded, setIsPartnerExpanded] = useState(false);
  const [flagClicked, setFlagClicked] = useState(false);
  const darkMode = useSelector((state) => state.darkMode);
  const { user } = useSelector((state) => state.userInfo);
  const { flaggedTickets } = useSelector((state) => state.tickets);

  const theme = createTheme(themeSettings(darkMode ? "dark" : "light"));
  const colors = tokens(theme.palette.mode);

  const priorityColor = getPriorityLabel(Log_or_Ticket_Priority);
  const companyLogo = getTicketLogo(Department_Name);
  const { singleTicket, singleTicketLoading } = useSelector(
    (state) => state.userInfo
  );
  useEffect(() => {
    if (flaggedTickets) {
      const isFlagged = flaggedTickets.some(ticket => ticket.ticket_number === Log_or_Ticket_Number)
      if (isFlagged) {
        setFlagClicked(true);
      }
    }
    }, [flaggedTickets])

  const parseTicketNumber = (reference) => {
    if (!reference) {
      return reference;
    }
    const match = reference.match(/\d+/);
    const ticket_number = match ? parseInt(match[0]) : null;
    if (ticket_number === null) {
      console.log("Unable to parse a Partner Ticket Number!");
    }
    return ticket_number;
  };

  const toggleExpandPartnerDetail = (reference) => {
    setIsPartnerExpanded(!isPartnerExpanded);
    const ticket_number = parseTicketNumber(reference);
    if (ticket_number === null) {
      console.log("Unable to parse a Partner Ticket Number!");
    } else {
      let partnetTicket = singleTicket.filter(
        (item) => item[TICKET_NUMBER] == matchReferenceNumber
      )[0];
      if (!partnetTicket || partnetTicket.length === 0) {
        dispatch(getUserTicketSingle({ organization_id: 1, ticket_number }));
      }
    }
  };

  const handleLinkClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleFlagClick = () => {
    if (flagClicked) {
      dispatch(deleteFlaggedTicket(Log_or_Ticket_Number));
      dispatch(removeFlag(Log_or_Ticket_Number));
    }else{
      const body = {
        customer: user.customer_id,
        user: user.id,
        ticket_number: Log_or_Ticket_Number,
      }

      dispatch(createFlaggedTicket(body));
      dispatch(addFlag(body));
    }
    setFlagClicked(!flagClicked);

  };

  const matchReferenceNumber = parseTicketNumber(Partner_Reference);
  const partnetTicket = singleTicket.filter(
    (item) => item[TICKET_NUMBER] === matchReferenceNumber
  )[0];

  return (
    <Accordion
      key={`${Log_or_Ticket_Number}-Accordian`}
      className="accordianSum"
      expanded={expanded === Log_or_Ticket_Number}
      // onChange={handleChange(Log_or_Ticket_Number)}
      style={{ boxShadow: "none", borderRadius: "8px" }}
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        className="accordianSum"
        expandIcon={<ExpandMoreIcon className="accordianSum" />}
        aria-controls={`${Log_or_Ticket_Number}-content`}
        key={`${Log_or_Ticket_Number}-Summary`}
        style={{ paddingRight: "10px", borderRadius: "8px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={"auto"} sm={"auto"}>
            <Avatar className="ticket-logo" src={companyLogo}></Avatar>
          </Grid>

          <Grid item xs={"auto"} sm={"auto"} >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12}>
                <div className="project-heading">
                  {Subject.length > 100
                    ? `${Subject.slice(0, 100)}...`
                    : Subject}
                </div>
              </Grid>
              <Grid item xs={"auto"} sm={"auto"}>
                <div className="project-ticket">#{Log_or_Ticket_Number}</div>
              </Grid>
              <Grid item xs={"auto"} sm={"auto"}>
                <div className="project-type">{Classification}</div>
              </Grid>
              {/*       <Grid item xs={"auto"} sm={"auto"}>
                <div className="priority-text">Priority: </div>
                </Grid> */}
              <Grid item xs={"auto"} sm={"auto"}>
                <div className={`project-priority ${priorityColor}`}>
                  {Log_or_Ticket_Priority}
                </div>
              </Grid>
              <Grid item xs={"auto"} sm={"auto"} >
              <div style={{ marginTop: '-2px', marginBottom: '2px' }}>
                  <LinkIcon onClick={() => handleLinkClick(Link)} />
                </div>
              </Grid>
              <Grid item xs={"auto"} sm={"auto"}>
              <div style={{ marginTop: '-2px', marginBottom: '2px' }}>
                  {flagClicked ? (
                    <FlagIcon sx={{color:colors.orangeAccent[500]}}onClick={() => handleFlagClick()} />
                  ) : (
                    <OutlinedFlagIcon onClick={() => handleFlagClick()} />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        style={{ borderRadius: "8px" }}
        key={`${Log_or_Ticket_Number}-Details`}
      >
        <TicketDetailComponent
          {...{
            Department_Name,
            Log_or_Ticket_Status,
            Department_Name,
            Log_or_Ticket_Number,
            Date_Created,
            Subject,
            Classification,
            Due_Date,
            Taxonomy,
          }}
        />
        {Partner_Reference ? (
          <>
            <Grid
              container
              spacing={2}
              className="align-items-top"
              onClick={() => toggleExpandPartnerDetail(Partner_Reference)}
            >
              <Grid item xs={12} sm={12}>
                <div className="detail-reference">
                  <LinkIcon onClick={() => handleLinkClick(Partner_Link)} />{" "}
                  Partner Reference:
                  <ExpandMoreIcon />
                </div>
              </Grid>
            </Grid>
            {isPartnerExpanded ? (
              !singleTicketLoading ? (
                <div>
                  {partnetTicket ? (
                    <TicketDetailComponent {...partnetTicket} />
                  ) : (
                    <div>Partner Ticket not found</div>
                  )}{" "}
                </div>
              ) : (
                <Grid container spacing={2} className="align-items-top">
                  <Grid item xs={12} sm={12}>
                    <div className="detail-partner-detail">
                      <Code />
                    </div>
                  </Grid>
                </Grid>
              )
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm={12}>
          <CommentSectionComponent commentId={Log_or_Ticket_Number} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TicketAccordion;
