import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedApiCall } from "hubl/utils/helpers";

// Async thunks
export const fetchUserMonitorUserLogins = createAsyncThunk(
  "userMonitor/fetchUserMonitorLogins",
  async ({ customer, user, usertype }, thunkAPI) => {
    const body = {
      fields: ["first_name", "last_name"],
      customer_id: customer,
      user_id: user,
      user_type: usertype
    }

    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/logins/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);

export const fetchUserMonitorReportClickByReport = createAsyncThunk(
  "userMonitor/fetchUserMonitorReportClickByReport",
  async ({ customer, user, usertype }, thunkAPI) => {
    const body = {
      fields: ["report_name"],
      customer_id: customer,
      user_id: user,
      user_type: usertype
    }
    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/reportclicks/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);
export const fetchUserMonitorReportClickByDates = createAsyncThunk(
  "userMonitor/fetchUserMonitorReportClickByDates",
  async ({ customer, user, usertype }, thunkAPI) => {
    const body = {
      fields: ["date_clicked"],
      customer_id: customer,
      user_id: user,
      user_type: usertype
    }

    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/reportclicks/",
        "POST",
        body,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);

export const fetchUserMonitorSummaryCustomerUsers = createAsyncThunk(
  "userMonitor/fetchUserMonitorSummaryCustomerUsers",
  async ({ customer, user, usertype }, thunkAPI) => {
    const body = {
      fields: ["customer_name"],
      customer_id: customer,
      user_id: user,
      user_type: usertype
    }

    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/summary/",
        "POST",
        body,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);

export const fetchUserMonitorSummaryCustomerUserNames = createAsyncThunk(
  "userMonitor/fetchUserMonitorSummaryCustomerUserNames",
  async ({ customer, user, usertype }, thunkAPI) => {
    const body = {
      fields: ["customer_name", "full_name"],
      customer_id: customer,
      user_id: user,
      user_type: usertype
    }

    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/summary/",
        "POST",
        body,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);


export const fetchUserMonitorSummaryUserLoginSummary = createAsyncThunk(
  "userMonitor/fetchUserMonitorSummaryUserLoginSummary",
  async ({ customer, user, usertype }, thunkAPI) => {
    const body = {
      customer_id: customer,
      user_id: user,
      user_type: usertype
    }

    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/logins/summary/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);

export const fetchUserMonitorCustomerList = createAsyncThunk(
  "userMonitor/fetchUserMonitorSummaryCustomerList",
  async (_, thunkAPI) => {
    const body = {
      fields: ["customer_name"],
    }

    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/summary/",
        "POST",
        body,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);

export const fetchUserMonitorUserList = createAsyncThunk(
  "userMonitor/fetchUserMonitorSummaryUserList",
  async ({ customer }, thunkAPI) => {
    const body = {
      fields: ["full_name", "isActive"],
      customer_id: customer,
    }

    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/summary/",
        "POST",
        body,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);

export const fetchUserMonitorUserTypeList = createAsyncThunk(
  "userMonitor/fetchUserMonitorSummaryUserTypeList",
  async ({ customer, user }, thunkAPI) => {
    const body = {
      fields: ["user_types"],
      customer_id: customer,
      user_id: user
    }

    try {
      const res = await makeAuthenticatedApiCall(
        "api/usermonitor/summary/",
        "POST",
        body,
        thunkAPI
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.res.data);
    }
  }
);



// Slice
export const userMonitorSlice = createSlice({
  name: "userMonitorReducer",
  initialState: {
    customerUsers: [],
    customerUsersLoading: false,
    customerUserNames: [],
    customerUserNamesLoading: false,
    
    userLogins: [],
    userLoginsLoading: false,

    userLoginSummary: [],
    userLoginSummaryLoading: false, 

    clicksByReport: [],
    clicksByReportLoading: false,
    clicksByDate: [],
    clicksByDateLoading: false,
    customerList: [],
    customerListLoading: false,
    userList: [],
    userListLoading: false,
    userTypeList: [],
    userTypeListLoading: false,

  },
  reducers: {},
  extraReducers: (builder) => {
    // fetchTest
    builder
      .addCase(fetchUserMonitorSummaryCustomerUsers.pending, (state) => {
        state.customerUsersLoading = true;
      })
      .addCase(
        fetchUserMonitorSummaryCustomerUsers.fulfilled,
        (state, action) => {
          state.customerUsersLoading = false;
          state.customerUsers = action.payload;
        }
      )
      .addCase(fetchUserMonitorSummaryCustomerUsers.rejected, (state) => {
        state.customerUsersLoading = false;
      })

      .addCase(fetchUserMonitorSummaryCustomerUserNames.pending, (state) => {
        state.customerUserNamesLoading = true;
      })
      .addCase(fetchUserMonitorSummaryCustomerUserNames.fulfilled, (state, action) => {
        state.customerUserNamesLoading = false;
        state.customerUserNames = action.payload;
      })  
      .addCase(fetchUserMonitorSummaryCustomerUserNames.rejected, (state) => {
        state.customerUserNamesLoading = false;
      })

      .addCase(fetchUserMonitorUserLogins.pending, (state) => {
        state.userLoginsLoading = true;
      })
      .addCase(fetchUserMonitorUserLogins.fulfilled, (state, action) => {
        state.userLoginsLoading = false;
        state.userLogins = action.payload;
      })
      .addCase(fetchUserMonitorUserLogins.rejected, (state) => {
        state.userLoginsLoading = false;
      })


      .addCase(fetchUserMonitorSummaryUserLoginSummary.pending, (state) => {
        state.userLoginSummaryLoading = true;
      })
      .addCase(fetchUserMonitorSummaryUserLoginSummary.fulfilled, (state, action) => {
        state.userLoginSummaryLoading = false;
        state.userLoginSummary = action.payload;
      })
      .addCase(fetchUserMonitorSummaryUserLoginSummary.rejected, (state) => {
        state.userLoginSummaryLoading = false;
      })


      .addCase(fetchUserMonitorReportClickByReport.pending, (state) => {
        state.clicksByReportLoading = true;
      })
      .addCase(
        fetchUserMonitorReportClickByReport.fulfilled,
        (state, action) => {
          state.clicksByReportLoading = false;
          state.clicksByReport = action.payload;
        }
      )
      .addCase(fetchUserMonitorReportClickByReport.rejected, (state) => {
        state.clicksByReportLoading = false;
      })

      .addCase(fetchUserMonitorReportClickByDates.pending, (state) => {
        state.clicksByDateLoading = true;
      })
      .addCase(
        fetchUserMonitorReportClickByDates.fulfilled,
        (state, action) => {
          state.clicksByDateLoading = false;
          state.clicksByDate = action.payload;
        }
      )
      .addCase(fetchUserMonitorReportClickByDates.rejected, (state) => {
        state.clicksByDateLoading = false;
      })

      .addCase(fetchUserMonitorCustomerList.pending, (state) => {
        state.customerListLoading = true;
      })
      .addCase(fetchUserMonitorCustomerList.fulfilled, (state, action) => {
        state.customerListLoading = false;
        state.customerList = action.payload;
      })
      .addCase(fetchUserMonitorCustomerList.rejected, (state) => {
        state.customerListLoading = false;
      })
      

      .addCase(fetchUserMonitorUserList.pending, (state) => {
        state.userListLoading = true;
      })
      .addCase(fetchUserMonitorUserList.fulfilled, (state, action) => {
        state.userListLoading = false;
        state.userList = action.payload;
      })
      .addCase(fetchUserMonitorUserList.rejected, (state) => {
        state.userListLoading = false;
      })

      .addCase(fetchUserMonitorUserTypeList.pending, (state) => {
        state.userTypeListLoading = true;
      })
      .addCase(fetchUserMonitorUserTypeList.fulfilled, (state, action) => {
        state.userTypeListLoading = false;
        state.userTypeList = action.payload;
      })
      .addCase(fetchUserMonitorUserTypeList.rejected, (state) => {
        state.userTypeListLoading = false;
      });

  },
});

export default userMonitorSlice.reducer;
