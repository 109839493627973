import React, { useState, useEffect, useRef } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { setReportConfig } from "slice/reportConfig";
import { useDispatch, useSelector } from "react-redux";
import { models } from "powerbi-client";
import { useMediaQuery } from "react-responsive";
import Grid from "@mui/material/Grid";
import { Instagram } from "react-content-loader";
import { getReportList, selectReport, getEmbedInfo } from "slice/pbi";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const PowerBIContent = () => {
  const [validToken, setValidToken] = useState(true);
  const initialRender = useRef(true);
  let { isAuthenticated, loading, tokenUpdateLoading, user } = useSelector(
    (state) => state.userInfo
  );

  const reportConfig = useSelector((state) => state.reportConfig);
  const [, setReport] = useState();
  const {
    pbiThemeloading,
    embedInfo,
    embedInfoloading,
    userReportList,
    report_id,
    reportList,
    reportListLoading,
  } = useSelector((state) => state.pbiReducer);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const layoutType = isMobile
    ? models.LayoutType.MobilePortrait
    : models.LayoutType.Master;

  const cssClass = isMobile
    ? "dashboard-styling-class-mobile"
    : "dashboard-styling-class";

  const boxClass = isMobile ? "powerbi-box-mobile" : "powerbi-box";
  const size = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userReportList) {
      const selected_report = report_id
        ? report_id
        : userReportList[0].report_id;

        if(reportList[selected_report]) {
      dispatch(
        setReportConfig({
          embedUrl: reportList[selected_report].embedUrl,
        })
      );
    }}
  }, [embedInfo]);

  useEffect(() => {
    if (user) {
      const value_list = user.filter_values.split(",");
      if (value_list[0] !== "All") {
        const report_filter = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: user.filter_table,
            column: user.filter_field,
          },
          operator: "In",
          values: value_list,
          filterType: models.FilterType.BasicFilter,
          requireSingleSelection: true,
        };

        dispatch(
          setReportConfig({
            filters: [report_filter],
          })
        );
      }
    }
  }, [user]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    dispatch(getEmbedInfo(reportList));
  }, [reportList]);

  useEffect(() => {
    if (isAuthenticated && !tokenUpdateLoading && !validToken) {
      dispatch(getReportList()).then(() => {
        setValidToken(true);
      });
    }
  }, [validToken, isAuthenticated, loading, tokenUpdateLoading]);

  useEffect(() => {
    const zoomLevelCalc = isMobile ? 1 : (size.width - 260) / 1560;
    if (size.width) {
      dispatch(
        setReportConfig({
          settings: {
            navContentPaneEnabled: false,
            layoutType: layoutType,
            zoomLevel: zoomLevelCalc,
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        })
      );
    }
  }, [size]);
  const eventHandlersMap = new Map([
    [
      "loaded",
      function () {
        // console.log("Report has loaded");
      },
    ],
    [
      "rendered",
      function () {
        // console.log("Report has rendered");
      },
    ],
    [
      "error",
      function (event) {
        if (event) {
          console.error(event.detail);

          switch (event.detail.message) {
            case "LoadReportFailed":
              const newReport = userReportList[0].report_id;
              dispatch(selectReport(newReport));
              dispatch(getReportList());
              break;
            case "TokenExpired":
              setValidToken(false);
              break;
            default:
              break;
          }
        }
      },
    ],
  ]);

  const colArray = [];
  for (let i = 0; i < 1; i++) {
    colArray.push(
      <Grid item xs={12} sm={12} key={i}>
        <Instagram />
      </Grid>
    );
  }

  return !reportListLoading &&
    !embedInfoloading &&
    !pbiThemeloading &&
    embedInfo !== null ? (
    <>
      <div className={boxClass}>
        <PowerBIEmbed
          embedConfig={reportConfig}
          eventHandlers={eventHandlersMap}
          cssClassName={cssClass}
          getEmbeddedComponent={(embedObject) => {
            setReport(embedObject);
          }}
        />
      </div>
      <div className="hr"></div>
      {/* {controlButtons} */}
    </>
  ) : (
    <Grid container spacing={2} className="align-items-top">
      {colArray}
    </Grid>
  );
};
export default PowerBIContent;
