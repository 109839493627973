import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useIdleTimer } from "react-idle-timer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TicketComponent from "../tickets/Tickets";
import ReleaseNoteComponent from "../notifications/ReleaseNotes";
import AdminComponent from "../admin/Admin";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/NavbarDash";
import PowerBIContent from "../../components/PowerBIContentBootstrap";
import BackdropComponent from "../../../hubl/components/backdrop/Backdrop";
import { verifyToken, logout } from "slice/user";
import {
  PBI_MENU,
  CLIENT_ENGAGEMENT_MENU,
  ADMIN_MENU,
  ZOHO_TICKET_MENU,
  RELEASE_NOTES,
} from "../../utils/types";
import "./dashboard.scss";

import { themeSettings } from "../../theme";
import { getEmbedInfo } from "slice/pbi";
import { refreshToken } from "slice/user";
// import { sessionTimeout } from "slice/session";

// import SessionPromptComponent from "../../components/popup/SessionPrompt";
const Dashboard = () => {
  const { section } = useSelector((state) => state.dashboardSection);
  const { timeout } = useSelector((state) => state.sessionTimeout);

  const { subscriptions, ticketsLoading } = useSelector(
    (state) => state.userInfo
  );
  let { isAuthenticated, tokenVerifyLoading, tokenUpdateLoading } = useSelector(
    (state) => state.userInfo
  );
  let { embedInfo, embedInfoloading, reportList } = useSelector(
    (state) => state.pbiReducer
  );
  // const [open, setOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const sessionTimeoutRef = useRef(null);
  const dispatch = useDispatch();
  const { reportPagesLoading } = useSelector((state) => state.pbiReducer);
  const { rememberMe } = useSelector((state) => state.userInfo);
  const darkMode = useSelector((state) => state.darkMode);
  const [hasTokenRefreshed, setHasTokenRefreshed] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(logout());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (hasTokenRefreshed && embedInfo && subscriptions.hasOwnProperty("pbi") && !tokenUpdateLoading && !tokenVerifyLoading) {
      const expirationDate = embedInfo.expiration
        ? new Date(embedInfo.expiration)
        : null;

      const currentDate = new Date();
      const expirationDatePlusBuffer = new Date(
        expirationDate.getTime() + 59 * 60000
      );

      if (!embedInfoloading && expirationDate < currentDate) {
        console.log(
          `Token expired value: ${expirationDate} CurrentDate ${currentDate} Actual Expiry ${expirationDatePlusBuffer}`
        );
        dispatch(getEmbedInfo(reportList));
      }
    }
  }, [tokenUpdateLoading, tokenVerifyLoading, hasTokenRefreshed])
  
  useEffect(() => {
    dispatch(refreshToken(rememberMe)).then(() => {
      setHasTokenRefreshed(true);
    });

    function runIntervalTask() {
      if (isAuthenticated && !tokenUpdateLoading) {
        dispatch(verifyToken()).then(() => {
          dispatch(refreshToken(rememberMe))
        });
      }
    }
    let interval = setInterval(runIntervalTask, 20000);
    return () => clearInterval(interval);
  }, []);

  const topRef = useRef(null);
  let navigate = useNavigate();

  const theme = createTheme(themeSettings(darkMode ? "dark" : "light"));

  const contentSwitch = () => {
    switch (section) {
      case PBI_MENU:
        return <PowerBIContent />;
      case CLIENT_ENGAGEMENT_MENU:
        return <TicketComponent />;
      case ADMIN_MENU:
        return <AdminComponent />;
      case ZOHO_TICKET_MENU:
        return <TicketComponent />;
      case RELEASE_NOTES:
        return <ReleaseNoteComponent />;
      default:
        return <></>;
    }
  };

  function subscriptionLoadingCheck(subs, sub, loading) {
    let sub_complete;
    if (subs.hasOwnProperty(sub)) {
      if (!loading) {
        sub_complete = true;
      } else {
        sub_complete = false;
      }
    } else {
      sub_complete = true;
    }
    return sub_complete;
  }
  useEffect(() => {
    let pbi_complete;
    let cep_complete;
    if (subscriptions) {
      pbi_complete = subscriptionLoadingCheck(
        subscriptions,
        "pbi",
        reportPagesLoading
      );
      cep_complete = subscriptionLoadingCheck(
        subscriptions,
        "cep",
        ticketsLoading
      );
    }
    if (pbi_complete && cep_complete) {
      setBackdropOpen(false);
    }
  }, [reportPagesLoading, ticketsLoading, subscriptions]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  // const handleClose = () => {
  //   setOpen(false);
  //   dispatch(sessionTimeout(false));
  // };
  const onAction = (e) => {
    clearTimeout(sessionTimeoutRef.current);
  };
  const onActive = (e) => {
    clearTimeout(sessionTimeoutRef.current);
  };
  // const onIdle = (e) => {
  //   if (!remember_me) {
  //   dispatch(sessionTimeout(true));
  //   setOpen(true);
  //   sessionTimeoutRef.current = setTimeout(() => dispatch(logout()), 15 * 1000);
  //   }
  // };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTimes,
  } = useIdleTimer({
    // onIdle,
    onActive,
    onAction,
    timeout: timeout,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 250,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  return (
    <>
      <Helmet>
        <title>Analytics | Landing</title>
        <meta name="Portal Layout" content={"Landing Page"} />
      </Helmet>
      {/* <SessionPromptComponent open={open} onClose={handleClose} /> */}
      <BackdropComponent open={backdropOpen} />

      <div className="home" ref={topRef}>
        <Sidebar />
        <hr />
        <div className="homeContainer">
          <Navbar />
          <ThemeProvider theme={theme}>{contentSwitch()} </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
