
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export const CustomTooltip = ({
    active,
    payload,
    xaxisDataKey,
    xaxisLabel,
    yaxisDataKey,
    yaxisLabel,
  }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      if (active && payload && payload.length) {
        return (
          <Card
            sx={{
              minWidth: 150,
              margin: "10px",
              textAlign: "left",
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                }}
                gutterBottom
              >
                {xaxisLabel}: {data[xaxisDataKey]}
              </Typography>
              <Typography variant="h5" component="h2">
                {yaxisLabel}: {data[yaxisDataKey]}
              </Typography>
            </CardContent>
          </Card>
        );
      }
      return null;
    }
  };