import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { List } from "react-content-loader";

function DisplayTableComponent({
  data,
  columns,
  showCheckboxes = false,
  density = "compact",
  height,
  onCellClick,
  isLoading = false,
  onGridClick, // New prop
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const keys = data.length > 0 ? Object.keys(data[0]) : null; // Get the keys from the first object


  const handleCellClick = (params, event) => {
    event.stopPropagation();
    if (onCellClick) {
      onCellClick(params, event);
    }
  };

  return keys ? (
    <Box
      height={height}
      onClick={() => {
        if (onGridClick) {
          onGridClick();
        }
      }}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.orangeAccent[500],
          color: colors.primary[500],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary,
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.primary[900],
          color: colors.primary[100],
        },
        "& .MuiDataGrid-cell:hover": {
          backgroundColor: colors.primary[600],
          cursor: "pointer",
        },
        "& .MuiCheckbox-root": {
          color: `${colors.primary[300]} !important`,
        },
        "& .MuiBox-root": {
          color: `${colors.orangeAccent[500]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[400]} !important`,
        },
        "& .clicked": {
          backgroundColor: colors.orangeAccent[600],
        },
      }}
    >
      {!isLoading ? (
        <>
          <DataGrid
            checkboxSelection={showCheckboxes}
            density={density}
            rows={data}
            columns={columns}
            onCellClick={handleCellClick}
            components={{ Toolbar: GridToolbar }}
            sx={{
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  marginTop: "1em",
                  marginBottom: "1em",
                },
            }}
          />
        </>
      ) : (
        <List />
      )}
    </Box>
  ) : (
    <></>
  );
}

export default DisplayTableComponent;
