import * as React from 'react';
import Switch from '@mui/material/Switch';
import "./switch.scss";

export default function SwitchComponent({checked, onChange, label}) {


  return (
    <>
    <div className="switch-label">{label}</div>
    <Switch
      checked={checked}
      onChange={(e) => onChange(e)}
      inputProps={{ 'aria-label': 'controlled' }}
    /></>
  );
}