import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerComponent({value, label, onChange}) {
  return (

    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="select-label">{label}</div>
        <DatePicker
          hiddenLabel
          value={value}
          onChange={(e) => onChange(e)}
        />
    </LocalizationProvider>
  );
}