import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import "./select.scss";

function SelectComponent({
  title,
  options,
  value,
  name,
  onChange,
  isMultiple,
}) {
  const allSelected = value.length === options.length;
  return (
    <FormControl className="" fullWidth>
      <div className="select-label">{title}</div>
      <Select
        className="select-object formControl"
        id={`select-${name}`}
        value={value.length > 0 ? value : []}
        onChange={(event) => onChange(event, options, name)}
        name={name}
        multiple={isMultiple}
        renderValue={(selected) => {
          if (isMultiple) {
            switch (true) {
              case selected.length == 0:
                return "All";
              case selected.length > 1:
                if (selected.length === options.length) {
                  return "All";
                } else {
                  return "...";
                }
              default:
                return selected.join(", ");
            }
          } else {
            return options.find((option) => option.value === selected).label;
          }
        }}
      >
        {isMultiple ? (
          <MenuItem key="all" value="All">
            <Checkbox key={`check-all`} checked={allSelected} />
            <ListItemText key={`text-all`} primary="Select All" />
          </MenuItem>
        ) : (
          <></>
        )}

        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox
              key={`check-${option.value}`}
              checked={value.indexOf(option.value) > -1}
              // {value.findIndex(obj => obj.value === option.value && obj.label === option.label) > -1}
            />
            <ListItemText key={`text-${option.value}`} primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectComponent;
