import React, { useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
  Rectangle,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import "./bar.scss";

export const generateFillColors = (data, colorList, singleColor) => {
  if (colorList && data.length) {
    return data.map((item, index) => {
      const colorIndex = index % Object.keys(colorList).length;
      if (singleColor) {
        return { ...item, fill: colorList[0] };
      } else {
        return { ...item, fill: colorList[colorIndex.toString()] };
      }
    });
  } else {
    return {};
  }
};

const HorizontalBarChartComponent  = ({
  data,
  xaxisDataKey,
  xaxisLabel,
  yaxisDataKey,
  yaxisLabel,
  borderBottom = 60,
  fillColors,
  singleColor = false,
  onBarClick,
  activeBarIndex

}) => {


  const containerStyle = { height: "100%", width: "100%"};
  const dataWithFillColors = generateFillColors(data, fillColors, singleColor);

  const CustomBar = (props) => {
    const { fill, x, y, width, height, index } = props;
    let isActive = true
    if (activeBarIndex) {
      isActive = index === activeBarIndex;
    }
    
    return (
      <g>
        {isActive && activeBarIndex &&
          <Rectangle radius={[0, 3, 3, 0]} x={x } y={y - 3} height={height + 5} width={width + 5} fill={fill} />}
        <Rectangle
          {...props}
          radius={[0, 3, 3, 0]} // adjust radius for horizontal bars
          fill={isActive ? fill : "grey"}
          x={x}
          y={y}
          width={width}
          height={height}
        />
      </g>
    );
  };

  const handleBarClick = (data) => {
    if (onBarClick) {
      onBarClick(data);
    }
  };
  function truncateLabel(label, maxLength) {
    if (!label) return "";
    if (label.length <= maxLength) return label;
    return label.slice(0, maxLength) + "...";
  }

  const CustomTick = (props) => {
    const { x, y, payload } = props;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={-12} dy={16} textAnchor="end" fill="#666" style={{ whiteSpace: 'nowrap' }}>
          {truncateLabel(payload.value, 10)}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%" style={containerStyle} >
      <BarChart
        layout="vertical" // This makes the chart horizontal
        data={dataWithFillColors}
  margin={{ top: 10, right: 50, left: 50, bottom: borderBottom }}
        onClick={handleBarClick}
        cursor="pointer"

      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          type="category"
          dataKey={xaxisDataKey}
          textAnchor="end"
          width={60}
          angle={0}
          dx={0}
          interval={0}
          tick={<CustomTick />} 
        />

        <XAxis type="number">
          <Label
            value={yaxisLabel}
            position="bottom"
            style={{ textAnchor: "middle" }}
           
  
          />
        </XAxis>
        <Tooltip
          content={
            <CustomTooltip
              xaxisDataKey={xaxisDataKey}
              xaxisLabel={xaxisLabel}
              yaxisDataKey={yaxisDataKey}
              yaxisLabel={yaxisLabel}
            />
          }
        />
        <Bar
          dataKey={yaxisDataKey}
          shape={(props) => <CustomBar {...props} index={props[xaxisDataKey]} />}
          label={yaxisLabel}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChartComponent ;
